import React, { useState, useRef, useEffect } from "react";
import { usePositioner, useResizeObserver, useMasonry } from "masonic";
import styled from "styled-components";
import {
  useResponses,
  useGrid,
  useBuckets,
  useSize,
  useScroller,
  useGridResponses,
  useSnapshotHistory
} from "hooks";
import { ErrorBoundary } from "react-error-boundary";
import SpinnerIcon from "react-spinkit";
import MasonryListCard from "components/Facelift/MasonryListCard";
import GridHeader from "components/Facelift/GridHeader";
import * as sizes from "utils/facelift/gridMeasurements";
import { Bucket } from "types/facelift";
import FallbackGrid from "./FallbackGrid";
import EmptyGrid from "./EmptyGrid";
import { GridWrapper } from "./styled";
import useApp from "context/AppContext";

function ListView() {
  const resetResponses = useResponses((s) => s.resetResponses);
  const updateSearching = useGrid((s) => s.updateSearching);
  const updateSelectedBucket = useBuckets((s) => s.updateSelectedBucket);

  const { appCtx } = useApp();
  const isStudyLoaded = useGrid((s) => s.isStudyLoaded);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const updateGridRef = useGrid((s) => s.updateGridRef);
  const { width, height } = useSize(containerRef);
  const { scrollTop, isScrolling } = useScroller(containerRef);

  function handleReset() {
    updateSearching(true);
    resetResponses();
    updateSelectedBucket({} as Bucket);
  }

  useEffect(() => {
    if (containerRef && containerRef.current !== null) {
      updateGridRef(containerRef.current);
    }
  }, [containerRef]);

  return (
    <ErrorBoundary FallbackComponent={FallbackGrid} onReset={handleReset}>
      <GridWrapper style={{ width: sizes.GRID_WIDTH }} ref={containerRef}>
        <GridHeader />

        {isStudyLoaded === false || appCtx.loading === true ? (
          <Loading />
        ) : (
          <NewList
            width={width}
            height={height}
            scrollTop={scrollTop}
            isScrolling={isScrolling}
          />
        )}
      </GridWrapper>
    </ErrorBoundary>
  );
}

export default ListView;

function NewList({ width, height, scrollTop, isScrolling }: any) {
  const gridResponses = useResponses((s) => s.gridResponses);
  const isSearching = useGrid((s) => s.isSearching);
  const selectedBucket = useBuckets((s) => s.selectedBucket);
  const positioner = usePositioner(
    { width, columnCount: 1, columnGutter: 16 },
    [isSearching]
  );
  const resizeObserver = useResizeObserver(positioner);
  const allResponses = useGridResponses();
  // const bucketResponses = gridResponses;
  // const items =
  //   selectedBucket.id === undefined ? allResponses : bucketResponses;
  const items = allResponses;

  const MasonryGrid = useMasonry({
    positioner,
    resizeObserver,
    items,
    render: MasonryListCard,
    height,
    scrollTop,
    isScrolling,
    overscanBy: 5,
    itemHeightEstimate: 400,
    itemKey: (item) => item.id
  });

  if (items.length > 0) {
    return MasonryGrid;
  }

  return <EmptyGrid />;
}

function Loading() {
  return (
    <LoadingWrapper>
      <SpinnerIcon name="ball-spin-fade-loader" color="#635ec0" fadeIn="none" />
    </LoadingWrapper>
  );
}

const LoadingWrapper = styled.div`
  margin-top: 16rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SnapshotContainer = styled.div`
  margin: 1rem 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  width: 100%;
`;

const Snapshot = styled.div`
  border-radius: 9999px;
  background: #635ec0;
  color: white;
  font-size: 1.1rem;
  padding: 0.5rem 1rem;
`;
